var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{staticClass:"multi-col-validation px-5 py-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.operador')))]),_c('v-autocomplete',{staticClass:"pt-2",attrs:{"items":_vm.itemsAerolinea,"search-input":_vm.searchAerolinea,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.operador'),"outlined":"","dense":"","item-text":"name","item-value":"id","single-line":""},on:{"update:searchInput":function($event){_vm.searchAerolinea=$event},"update:search-input":function($event){_vm.searchAerolinea=$event},"change":_vm.changeAerolinea},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.operador'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.model.aerolinea_id),callback:function ($$v) {_vm.$set(_vm.model, "aerolinea_id", $$v)},expression:"model.aerolinea_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.numeroVuelo')))]),_c('v-text-field',{ref:"numero_de_vuelo",staticClass:"pt-2",attrs:{"label":_vm.$t('lbl.numeroVuelo'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.model.numero_de_vuelo),callback:function ($$v) {_vm.$set(_vm.model, "numero_de_vuelo", $$v)},expression:"model.numero_de_vuelo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.from')))]),_c('vc-date-picker',{staticClass:"pt-2",attrs:{"outlined":"","dense":"","min-date":new Date(),"mode":"date","model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({staticClass:"pt-2",attrs:{"label":_vm.$t('lbl.from'),"outlined":"","dense":"","value":inputValue},on:{"change":_vm.changeFrom}},inputEvents))]}}]),model:{value:(_vm.model.from),callback:function ($$v) {_vm.$set(_vm.model, "from", $$v)},expression:"model.from"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.to')))]),_c('vc-date-picker',{staticClass:"pt-2",attrs:{"disabled":!_vm.model.from,"outlined":"","dense":"","min-date":_vm.$moment(_vm.model.from)
              .add(1, 'day')
              .toDate(),"mode":"date","model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var inputValue = ref.inputValue;
              var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({ref:"to",staticClass:"pt-2",attrs:{"label":_vm.$t('lbl.to'),"outlined":"","dense":"","value":inputValue}},inputEvents))]}}]),model:{value:(_vm.model.to),callback:function ($$v) {_vm.$set(_vm.model, "to", $$v)},expression:"model.to"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.ruta')))]),_c('v-autocomplete',{staticClass:"pt-2",attrs:{"items":_vm.itemsOrigen,"search-input":_vm.searchOrigen,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.origen'),"outlined":"","dense":"","item-text":"name","item-value":"id","single-line":"","return-object":""},on:{"update:searchInput":function($event){_vm.searchOrigen=$event},"update:search-input":function($event){_vm.searchOrigen=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultOrigen ? _vm.$t('booking.noResultSearchFlight') : _vm.$t('booking.origenSearch'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAirplaneTakeoff)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.model.origen),callback:function ($$v) {_vm.$set(_vm.model, "origen", $$v)},expression:"model.origen"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',{staticStyle:{"color":"#FFFFFF"}},[_vm._v(_vm._s(_vm.$t('lbl.ruta')))]),_c('v-autocomplete',{staticClass:"pt-2",attrs:{"items":_vm.itemsDestino,"search-input":_vm.searchDestino,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.destino'),"outlined":"","dense":"","item-text":"name","item-value":"id","single-line":"","return-object":""},on:{"update:searchInput":function($event){_vm.searchDestino=$event},"update:search-input":function($event){_vm.searchDestino=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultDestino ? _vm.$t('booking.noResultSearchFlight') : _vm.$t('booking.destinoSearch'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAirplaneTakeoff)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.model.destino),callback:function ($$v) {_vm.$set(_vm.model, "destino", $$v)},expression:"model.destino"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.semanaDay')))]),_c('v-sheet',{staticClass:"py-2"},[_c('v-slide-group',{attrs:{"multiple":""},model:{value:(_vm.model.frecuencia),callback:function ($$v) {_vm.$set(_vm.model, "frecuencia", $$v)},expression:"model.frecuencia"}},_vm._l((_vm.semanas),function(sema,index){return _c('v-slide-item',{key:index,staticClass:"px-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
              var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":"","x-small":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(sema)+" ")])]}}],null,true)})}),1)],1)],1)],1)],1),_c('v-divider'),_c('v-form',{staticClass:"multi-col-validation px-5 py-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"1"}},[(
            _vm.model.aerolinea_id ||
              _vm.model.numero_de_vuelo ||
              _vm.model.frecuencia.length > 0 ||
              _vm.model.origen ||
              _vm.model.destino ||
              _vm.model.from ||
              _vm.model.to
          )?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","small":"","color":"error"},on:{"click":function($event){return _vm.clearSearch()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEraserVariant)+" ")])],1):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"9"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.searchAll()}}},[_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnify)+" ")]),_c('span',{staticClass:"pl-5"},[_vm._v(_vm._s(_vm.$t('btn.search')))])],1)],1),(_vm.hay_result)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.itemsPerPage'),"type":"number","min":"5","hide-details":"","dense":"","outlined":""},on:{"input":function($event){return _vm.searchAll()}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1):_vm._e()],1)],1),(_vm.scheduleResult.length > 0)?_c('Flights'):(_vm.scheduleResult.length === 0 && _vm.hay_result)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table-heading, list-item-two-line, image, table-tfoot"}}):_vm._e(),(_vm.hay_result)?_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"12","cols":"12"}},[_c('v-pagination',{attrs:{"length":Math.round(_vm.pagination.total/_vm.itemsPerPage),"total-visible":"5"},on:{"input":_vm.onPageChange},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }